<template>
  <div>
    <div class="grid grid-cols-8 gap-12 md:gap-6 pt-3">  
      <div class="col-span-8 md:col-span-8" v-if="cartProducts.length > 0">
        <h1>Cart – {{ cartProductsLength }} items</h1>
        <div class="grid grid-cols-4 md:grid-cols-5 gap-3 md:gap-6">
          <div class="col-start-2 col-span-1"><b>Product</b></div>
          <div class="col-span-1"><b>Quantity</b></div>
          <div class="col-span-1"><b>Unit price</b></div>
          <div class="col-span-1"><b>Total</b></div>
        </div> 
        <template v-for="(cartProduct, index) in cartProducts">
          <div class="grid grid-cols-4 gap-3 md:grid-cols-5 md:gap-6 pt-2 pb-2 first:pt-0 last:pb-0" 
            v-if="cartProduct !== undefined && cartProduct.type !== 'shipping_fee'"
            :key="index"
          >
            <div class="hidden md:block">
              <img :src="api + cartProduct.image.formats.thumbnail.url"/>
            </div>
            <div class="col-span-1 md:col-span-1 flex flex-col justify-between items-start">
              {{ cartProduct.name }}
              <button @click="removeFromCart(cartProduct)" class="hidden md:block bg-black text-white rounded-full outline-none px-2">Remove</button>
            </div>
            <div class="grid grid-cols-3">
              <span class="cursor-pointer" @click="updateCart(cartProduct, false)">-</span>
              <span>{{ cartProduct.quantity }}</span>
              <span class="cursor-pointer" @click="updateCart(cartProduct, true)">+</span>
            </div>
            <div>
              {{ cartProduct.unitPrice | currency }}
            </div>
            <div>
              {{ cartProduct.unitPrice * cartProduct.quantity | currency }}
            </div>
            <div class="md:hidden">
              <button @click="removeFromCart(cartProduct)" class="bg-black text-white rounded-full outline-none px-2">Remove</button>
            </div>
          </div>
        </template> 
        <div class="flex justify-between pt-6">
          <button
            class="bg-black text-white rounded-full outline-none px-2"
            @click="$router.push({ name: 'Shop' })"
          >
            Continue shopping
          </button>
          <button
            class="bg-black text-white rounded-full outline-none px-2"
            @click="checkOut"
          >
            Continue to checkout
          </button>
        </div> 
      </div>
    </div>
    <slot/>
  </div>
</template>

<script>
import { extend } from 'vee-validate';
import { excluded, required } from 'vee-validate/dist/rules';

extend('excluded', excluded)
extend('required', {
  ...required,
  message: 'Select your country'
})

extend('objectNotEmpty', {
  validate: (value) => {
    console.log(value);
    if (Object.keys(value).length > 0) {
      return true;
    }
    return false;
  },
});

export default {
  name: 'Cart',
  metaInfo: {
    title: 'Cart'
  },
  data() {
    return {
      api: process.env.VUE_APP_API_URL,
      selected: '',
      shippingId: '',
    }
  },
  created() {
    if (this.products.length === 0) {
      this.$store.dispatch('fetchProducts')
    }
    if (this.countries.length === 0) {
      this.$store.dispatch('fetchCountries')
    }
  },
  mounted() {
    if (this.selectedShipping) {
      this.selected = this.selectedShipping
    }
  },
  methods: {
    checkOut() {
      this.$router.push({ name: 'Checkout' })
    },
    updateCart(cartProduct, increment) {
      this.$store.dispatch('updateProductQuantity', {cartItem: cartProduct, increment})
    },
    removeFromCart(cartProduct) {
      this.$store.dispatch('removeFromCart', cartProduct)
    },
  },
  computed: {
    products() {
      return this.$store.getters.products
    },
    inputCountries() {
      return this.$store.getters.inputCountries
    },
    countries() {
      return this.$store.getters.countries 
    },
    selectedShipping() {
      return this.$store.getters.shippingCountry
    },
    availableShipping() {
      return this.$store.getters.availableShipping
    },
    cartProducts() {
      return this.$store.getters.cartProducts
    },
    cartProductsLength() {
      return this.$store.getters.numberOfProductsInCart
    },
    shippingCosts() {
      return this.$store.getters.shippingTotal
    },
    subTotal() {
      return this.$store.getters.subTotal
    },
    totals() {
      return this.$store.getters.totals
    },
    totalWeight() {
      return this.$store.getters.totalWeight
    }
  }
}
</script>